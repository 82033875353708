@import "./variables";

@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~bootstrap/scss/bootstrap.scss';


html,
body,
#root {
	height: 100%;
	background: white;
	overflow-x: hidden;
}

.btn:focus,
.btn:active,
a:focus,
a:active {
	outline: none !important;
	box-shadow: none !important;
}

.auth {
	.auth-background-holder {
		height: 100vh;
		min-height: 100%;
	}
}

.auth-main-col,
.auth-background-col {
	position: relative;
}

.auth-main-col {
	margin: auto;
}

.auth-background-col {
	background: rgba($theme-text-color-primary, 0.6);
}

.auth-background-mask {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.1);
}

.auth-wrapper {
	background: white;
	height: 85vh;

	.auth-body {
		width: 360px;
	}

	.auth-heading {
		font-size: 1.5rem;
	}

	.auth-option {
		font-size: 0.875rem;

		>a {
			color: $theme-text-color-secondary;
		}
	}

	.forgot-password {
		>a {
			color: $theme-text-color-secondary;
		}
	}

	input:focus {
		box-shadow: none;
	}
}

.version-wrapper {
	position: absolute;
	bottom: 0;
	left: 48px;
	color: #cfcfcf;
	font-size: 0.75rem;
}

.button-group {
	padding-bottom: 4px;
	display: block;
}

@media (max-width: 767.98px) {
	.auth-background-col {
		display: none;
	}
}

.offscreen {
	position: absolute;
	left: -9999px;
}

.errmsg {
	background-color: lightpink;
	color: firebrick;
	font-weight: bold;
	padding: 0.5rem;
	margin-bottom: 0.5rem;
}

.top-container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.panel-body input {
	margin-right: 2px !important;
}

.panel-body label {
	margin-right: 10px;
}

.grid-wrapper {
	display: flex;
	flex: 1 1 auto;
	margin-top: 5px;
}

.grid-wrapper .panel {
	flex: 1 1 50%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.grid-wrapper .panel-body {
	flex: 1 1 auto;
	overflow: hidden;
	padding: 0;
	display: flex;
}

.grid-wrapper .panel-body>div {
	width: 100%;
}